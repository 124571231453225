<template>
  <FocusTrap>
    <div class="card" id="mycard">

      <div class="card-header header-elements-sm-inline py-sm-0" >
        <h5 class="card-title py-sm-3"> <h3>Book Outstanding List</h3></h5>
        <div class="header-elements">
          <form action="#">
            <div class="form-group-feedback form-group-feedback-right">
              <input  id="txtsearch" type="search" class="form-control wmin-sm-200" placeholder="Search..." autocomplete="off">
              <div class="form-control-feedback">
                <i class="icon-search4 font-size-base text-muted"></i>
              </div>
            </div>
          </form>
        </div>
      </div>

      <div class="table-responsive">
        <table id="circulation_table"
               class="table table-bordered table-columned"
               data-search="false"
               data-pagination="true"
               data-unique-id="id"
               data-show-refresh="false"
               data-show-columns="false"
               data-page-list="[10, 25, 50, 100, ALL]"
               data-show-footer="false"
               data-toggle="context"
               data-target=".context-table">
          <thead>
          <tr>
            <th data-field="id" data-class="d-none">id</th>
            <th data-formatter="runningFormatter" data-width="75">S.No</th>
            <th data-field="issue_date" data-width="108">Isssued On</th>
            <th data-field="due_date" data-width="108">Due On</th>
            <th data-field="member.reg_no" data-width="80">Regd No</th>
            <th data-field="member.name" >Member Name</th>
            <th data-field="book.name" >Book Name</th>
            <th data-field="book.author_name" >Authors</th>
            <th data-field="book.publisher.name" >Publisher</th>
          </tr>
          </thead>
          <tbody>
          </tbody>
          <tfoot>
          </tfoot>
        </table>
      </div>

      <!-- Context Menu -->
      <div id="context-menu" class="context-table">
        <div class="dropdown-menu">
          <a href="#" class="dropdown-item" @click="showModal"><i class="icon-printer" style="color: darkgreen"></i>Make Return</a>
          <div class="dropdown-divider"></div>
          <a href="#" class="dropdown-item" @click="loadData"><i class="icon-reset" style="color: darkgreen"></i>Refresh</a>

        </div>
      </div>
      <!-- End of Context Menu -->


      <FocusTrap>
        <vmodal name="bookreturn_window" transition="nice-modal-fade" :delay="100" :resizable="true" :width="700" :height="420" @before-open="beforeOpen" @before-close="beforeClose" @opened="afterOpen" >
          <!-- Centered buttons -->
          <div class="card" id="returncard" >
            <div class="card-header">
              <h6 class="card-title">Book Return Entry</h6>
            </div>

            <div class="card-body">
              <form action="#">
                <div class="form-group">
                  <label>Book Name:</label>
                  <input type="text" class="form-control" placeholder="Book Name" disabled v-if="circulation" v-model="circulation.book.name">
                </div>

                <div class="form-group">
                  <label>Book Title:</label>
                  <input type="text" class="form-control" placeholder="Book Title" disabled v-if="circulation" v-model="circulation.book.title">
                </div>

                <div class="form-group">
                  <label>Return Note:</label>
                  <textarea id="txtReturn_note" rows="3" cols="3" class="form-control" placeholder="" v-if="circulation" v-model="circulation.return_note"></textarea>
                </div>

                <div class="text-center">
                  <SaveButton title="Make Return" @handle_save_action="perform_save_action" />
                </div>
              </form>
            </div>
          </div>
          <!-- /centered buttons -->

      </vmodal>
      </FocusTrap>

    </div>
  </FocusTrap>
</template>

<script>
import ReceiptForm from '@/views/lms/receipt/ReceiptForm.vue'
import { userService } from '@/store/auth-header.js'
import { store } from '@/store/store.js'
import SaveButton from '../../../components/core/buttons/SaveButton'

export default {
  name: 'ReceiptView',
  components: {
    SaveButton,
    ReceiptForm
  },
  store,
  data () {
    return {
      mytable: {},
      circulation : JSON.parse('{"id":0,"status":"","issue_date":"2000-01-01","due_date":"2000-01-01","return_date":"2000-01-01","member_id":0,"book_id":0,"member":{"id":0,"status":"Active","type":"Student","reg_no":"","name":"","batch":"","department_id":1,"department":{"id":0,"name":""},"section_id":1,"section":{"id":0,"name":""},"email":"","mobile":"","image":""},"book":{"id":0,"status":"Active","code":"","name":"","title":"","language":"en","edition":"","edition_year":0,"publisher_id":0,"publish_year":0,"group_id":0,"category_id":0,"rack":"","qty":0,"price":0,"pages":0,"notes":"","image":"","publisher":{"id":0,"name":""},"category":{"id":0,"name":""},"group":{"id":0,"name":""},"authors":[]},"notes":"","return_note":""}'),
    }
  },
  created () {

  },
  mounted () {
    const self = this;

    this.$data.mytable = $('#circulation_table');

    this.$data.mytable.bootstrapTable();
    $('[data-toggle="popover"]').popover();

    this.$data.mytable.contextmenu({
      target: '#context-menu',
      scopes: 'tbody > tr',
      onItem: function (row, e) {
        let id = $(row.children('*')[0]).text();
        self.$data.circulation = JSON.parse('{"id":0,"status":"","issue_date":"2000-01-01","due_date":"2000-01-01","return_date":"2000-01-01","member_id":0,"book_id":0,"member":{"id":0,"status":"Active","type":"Student","reg_no":"","name":"","batch":"","department_id":1,"department":{"id":0,"name":""},"section_id":1,"section":{"id":0,"name":""},"email":"","mobile":"","image":""},"book":{"id":0,"status":"Active","code":"","name":"","title":"","language":"en","edition":"","edition_year":0,"publisher_id":0,"publish_year":0,"group_id":0,"category_id":0,"rack":"","qty":0,"price":0,"pages":0,"notes":"","image":"","publisher":{"id":0,"name":""},"category":{"id":0,"name":""},"group":{"id":0,"name":""},"authors":[]},"notes":"","return_note":""}');
        if ($(e.target).text() === 'Make Return') {
          self.$data.circulation = self.$data.mytable.bootstrapTable('getRowByUniqueId', id);
          self.showModal();
        } else if ($(e.target).text() === 'Remove') {
          self.removeDocument(id);
        }
      }
    })

    $('#txtsearch').keyup(function () {
      let val = $.trim($(this).val()).replace(/ +/g, ' ').toLowerCase();
      $('#circulation_table>tbody>tr').show().filter(function () {
        let text = $(this).text().replace(/\s+/g, ' ').toLowerCase();
        return !~text.indexOf(val);
      }).hide();
    });

    self.loadData();
  },
  methods: {
    closeModal () {
      this.$modal.hide('bookreturn_window');
    },
    showModal () {
      this.$modal.show('bookreturn_window');
      $('#txtReturn_note').focus();
    },
    afterOpen(){
      $('#txtReturn_note').focus();
    },
    beforeOpen () {
      console.log('beforeOpen')
    },
    beforeClose () {
      
    },
    loadData () {
      const self = this;

      const requestOptions = {
        method: 'GET',
        mode: 'cors',
        headers: userService.authHeader()
      }


      $('#mycard').block({
        message: '<i class="icon-spinner2 spinner"></i>',
        overlayCSS: {
          backgroundColor: '#fff',
          opacity: 0.8,
          cursor: 'wait',
          'box-shadow': '0 0 0 1px #ddd'
        },
        css: {
          border: 0,
          padding: 0,
          backgroundColor: 'none'
        }
      });

      self.$data.mytable.bootstrapTable('load', []);
      self.$data.mytable.bootstrapTable('showLoading');
      fetch(`${process.env.VUE_APP_ROOT_API}api/lms/circulations/pending`, requestOptions).then(userService.handleResponse).then(function (resp) {
        if (resp.ok) {
          if (!_.isNull(resp.data)) {
            self.$data.mytable.bootstrapTable('load', resp.data);
          }
        } else {
          swal({ title: 'Oops!', text: resp.msg, type: 'error' });
        }
      }).catch(function (err) {
        swal({ title: 'Oh noes!', text: err.toString(), type: 'error' });
      }).finally(()=>{
        self.$data.mytable.bootstrapTable('hideLoading');
        $('#mycard').unblock();
      });
    },
    perform_save_action(){
      const self = this;
      try{
        $('#returncard').block({
          message: '<i class="icon-spinner2 spinner"></i>',
          overlayCSS: {
            backgroundColor: '#fff',
            opacity: 0.8,
            cursor: 'wait',
            'box-shadow': '0 0 0 1px #ddd'
          },
          css: {
            border: 0,
            padding: 0,
            backgroundColor: 'none'
          }
        });

        console.log(JSON.stringify(self.$data.circulation));

        const requestOptions = {
          method: (self.$data.circulation.id == 0 ? 'POST' : 'PUT'),
          mode: 'cors',
          headers: userService.authHeader(),
          body: JSON.stringify(self.$data.circulation)
        }

        fetch(`${process.env.VUE_APP_ROOT_API}api/lms/circulation/return`, requestOptions).then(userService.handleResponse).then(function (resp) {
          if (resp.ok) {
            swal({ title: 'Success!', text: resp.msg, type: 'success', onClose: () => { $('#txtReturn_note').focus()}, timer:1500 });
            self.$data.circulation = JSON.parse('{"id":0,"status":"","issue_date":"2000-01-01","due_date":"2000-01-01","return_date":"2000-01-01","member_id":0,"book_id":0,"member":{"id":0,"status":"Active","type":"Student","reg_no":"","name":"","batch":"","department_id":1,"department":{"id":0,"name":""},"section_id":1,"section":{"id":0,"name":""},"email":"","mobile":"","image":""},"book":{"id":0,"status":"Active","code":"","name":"","title":"","language":"en","edition":"","edition_year":0,"publisher_id":0,"publish_year":0,"group_id":0,"category_id":0,"rack":"","qty":0,"price":0,"pages":0,"notes":"","image":"","publisher":{"id":0,"name":""},"category":{"id":0,"name":""},"group":{"id":0,"name":""},"authors":[]},"notes":"","return_note":""}');

            self.$emit('circulation_return_saved',resp.data);
            self.$modal.hide('bookreturn_window');

          } else {
            swal({ title: 'Oops!', text: resp.msg, type: 'error' , onClose: () => { $('#txtReturn_note').focus()}, timer:3000 });
          }
        }).catch(function (err) {
          swal({ title: 'Oh noes!', text: err.toString(), type: 'error' , onClose: () => { $('#txtReturn_note').focus()}, timer:3000 });
        }).finally(()=>{
          $('#returncard').unblock();
        });


        $('#returncard').unblock();
      }catch (e) {
        alert(e);
      }
    },
  }
}
</script>

<style scoped>
input:focus {
  background: #feff00;

}

textarea:focus {
  background: #feff00;

}

select:focus {
  background: #feff00;
}

button:focus {
  background: #feff00;
  background-color: #feff00!important;
  color: #0a0a0a!important;
}

</style>
